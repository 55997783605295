import {Inject, Injectable, InjectionToken} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  AccessRoleType,
  Agency,
  AgencyAlert,
  AgencyBanner,
  AgencyBannerType,
  AgencyCRMSettings,
  AgencyCustomDomainSettings,
  AgencyLender,
  AgencyRoleWisePermissions,
  Agent,
  AgentCrmPlatformPresenceInfo,
  AgentProfile,
  AgentSearchParams,
  AgentSignature,
  BillingDetailsChargebeeInvoiceEntry,
  EmailCRMMapping,
  EmailCRMMappingCreation,
  EnrichmentConfig,
  GlobalTrackingSetting,
  LeadAssignmentSettings,
  MediaWithType,
  NonMarketingContactConfig,
  PagedResult,
  RealSellerNotificationType,
  ServiceableArea,
  TeamMemberDetails
} from "fello-model";
import {AGENT_API_SERVICE_BASE_URL} from "./agent-api.service";

export const CHARGEBEE_DOMAIN_URL = new InjectionToken<string>("CHARGEBEE_DOMAIN_URL");

@Injectable({
  providedIn: "root"
})
export class AgencyApiService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(AGENT_API_SERVICE_BASE_URL) private agentBaseUrl: string) {
    this.baseUrl = `${this.agentBaseUrl}/agent/agency`;
  }

  getAgency(): Observable<Agency> {
    return this.http.get<Agency>(`${this.baseUrl}`);
  }

  listExternalProfiles(): Observable<EmailCRMMapping[]> {
    return this.http.get<EmailCRMMapping[]>(`${this.baseUrl}/external-profiles`);
  }

  deleteExternalProfiles(emailCRMMappings: EmailCRMMapping[]): Observable<unknown> {
    return this.http.delete<unknown>(`${this.baseUrl}/external-profiles`, {body: {emailCRMMappings}});
  }

  addExternalProfileBulk(emailCRMMappings: EmailCRMMapping[]): Observable<EmailCRMMappingCreation[]> {
    return this.http.post<EmailCRMMappingCreation[]>(`${this.baseUrl}/external-profiles`, {emailCRMMappings});
  }

  isAgentOnPlatform(crmUserId: string): Observable<AgentCrmPlatformPresenceInfo> {
    return this.http.get<AgentCrmPlatformPresenceInfo>(`${this.baseUrl}/is-on-platform/${crmUserId}`);
  }

  searchAgent(params: AgentSearchParams): Observable<PagedResult<Agent>> {
    return this.http.get<PagedResult<Agent>>(`${this.baseUrl}/agent`, {params: params as HttpParams});
  }

  searchAgentsToEmulate(params: AgentSearchParams): Observable<PagedResult<Agent>> {
    return this.http.get<PagedResult<Agent>>(`${this.baseUrl}/agent/emulate`, {params: params as HttpParams});
  }

  updateAgencyProfile(agency: Partial<Agency>): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}`, {agency: agency});
  }

  getAllAgencyMembers(): Observable<Partial<Agent>[]> {
    return this.http.get<Partial<Agent>[]>(`${this.baseUrl}/members`);
  }

  getRoleWisePermission(): Observable<AgencyRoleWisePermissions> {
    return this.http.get<AgencyRoleWisePermissions>(`${this.baseUrl}/access-role/permissions`);
  }

  getAllAgencyInvitedMembers(): Observable<Partial<Agent>[]> {
    return this.http.get<Agent[]>(`${this.baseUrl}/invited-members`);
  }

  getAllTeamMembers(): Observable<TeamMemberDetails[]> {
    return this.http.get<TeamMemberDetails[]>(`${this.baseUrl}/team-members`);
  }

  bulkInviteNewAgentsToAgency(
    invitedMemberEmails: string[],
    invitedMemberRole: AccessRoleType,
    sendInviteEmail: boolean
  ): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/bulk-invite-agents`, {
      invitedMemberEmails,
      invitedMemberRole,
      sendInviteEmail
    });
  }

  addNewAgentToAgency(agentProfileRequest: AgentProfile, sendInviteEmail: boolean): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/invite-new-agent`, {
      agentProfileRequest,
      sendInviteEmail
    });
  }
  getServiceableAreas(): Observable<ServiceableArea[]> {
    return this.http.get<ServiceableArea[]>(`${this.baseUrl}/serviceable-areas`);
  }

  planUpgradeRequest(remarks: string): Observable<unknown> {
    return this.http.post<unknown>(`${this.baseUrl}/plan/upgrade/request`, {remarks});
  }

  updateNonMarketingContactConfig(nonMarketingContactConfig: NonMarketingContactConfig): Observable<Agency> {
    return this.http.patch<Agency>(`${this.baseUrl}/non-marketing-contact-config`, {nonMarketingContactConfig});
  }

  getAgencySignature(): Observable<AgentSignature> {
    return this.http.get<AgentSignature>(`${this.baseUrl}/signature`);
  }

  updateNotificationReceiverEmails(receiverEmails: string[]): Observable<Agency> {
    return this.http.post<Agency>(`${this.baseUrl}/notification-receivers`, {receiverEmails});
  }

  saveRealSellerEventNotificationConfig(
    realSellerEventReceiverEmails: string[],
    realSellerNotificationType: RealSellerNotificationType
  ): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/real-seller-event-receivers`, {
      realSellerEventReceiverEmails,
      realSellerNotificationType
    });
  }

  setSubdomain(subdomain: string): Observable<Agency> {
    return this.http.post<Agency>(`${this.baseUrl}/sub-domain`, {subdomain});
  }

  getSubdomainAvailability(subdomain: string): Observable<{isAvailable: boolean}> {
    return this.http.post<{isAvailable: boolean}>(`${this.baseUrl}/sub-domain-availability`, {subdomain});
  }

  addCustomMailSendingDomain(sendingDomain: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/custom-sending-domain`, {sendingDomain});
  }

  deleteCustomMailSendingDomain(): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/custom-sending-domain`, {});
  }

  reVerifyCustomMailSendingDomain(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/custom-sending-domain/re-verify`, {});
  }

  verifyCustomMailSendingDomain(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/custom-sending-domain/verify`, {});
  }

  verifyCustomMailSendingDomainDMARC(): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/custom-sending-domain/verify-dmarc`, {});
  }

  addCustomMailSendingDomainEmail(emailId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/custom-sending-domain/add-email`, {emailId});
  }

  getOverriddenCustomMailSendingDomain(): Observable<AgencyCustomDomainSettings | null> {
    return this.http.get<AgencyCustomDomainSettings | null>(`${this.baseUrl}/custom-sending-domain/from-reference-agency`);
  }

  addLenderSettings(lender: AgencyLender): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/lender`, {lenderSettings: lender});
  }

  updateLenderMedia(lenderMedia: MediaWithType): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/lender/media`, {lenderMedia});
  }

  removeLenderSettings(): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/lender`);
  }

  updateLeadAssignmentSettings(settings: LeadAssignmentSettings): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/lead-assignment/settings`, {settings});
  }

  updateCRMSettings(crmSettings: AgencyCRMSettings): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/crm-settings`, {crmSettings});
  }
  updateAccHomeEstimateOverridePerc(perc: number): Observable<void> {
    return this.http.patch<void>(`${this.baseUrl}/home-estimate-override`, {perc});
  }

  getGlobalTrackingSetting(): Observable<GlobalTrackingSetting | undefined> {
    return this.http.get<GlobalTrackingSetting | undefined>(`${this.baseUrl}/global-tracking-setting`);
  }

  saveGlobalTrackingSetting(globalTrackingSetting: GlobalTrackingSetting): Observable<GlobalTrackingSetting> {
    return this.http.post<GlobalTrackingSetting>(`${this.baseUrl}/global-tracking-setting`, globalTrackingSetting);
  }

  updateEnrichmentConfig(enrichmentConfig: EnrichmentConfig) {
    return this.http.patch<void>(`${this.baseUrl}/enrichment-config`, {enrichmentConfig});
  }

  getAgencyBanners(): Observable<AgencyBanner[]> {
    return this.http.get<AgencyBanner[]>(`${this.baseUrl}/banner`);
  }

  dismissBanner(bannerType: AgencyBannerType): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/banner/${bannerType}/dismiss`, {});
  }
}
